import React from 'react'
import './Home.css' // Import file CSS riêng để giữ mã sạch hơn
import { Link } from 'react-router-dom'
import bgImage from '../bg.jpeg' // Điều chỉnh đường dẫn nếu cần
import doi from '../doi.jpeg'
const Home = () => {
  return (
    <>
      <header>
        <div className='overlay'>
          <h1>Our Love Journey</h1>
        </div>
        <nav>
          <ul className='nav-links'>
            <li>
              <Link to='/'>Home</Link>
            </li>
            {/* <li>
              <Link to='/about'>About</Link>
            </li> */}
            <li>
              <Link to='/heart'>Heart</Link>
            </li>
          </ul>
        </nav>
      </header>

      <section className='photo-gallery'>
        <h2>Memories</h2>
        <div className='gallery'>
          <div
            className='photo'
            style={{
              backgroundImage: `url(${bgImage})`,
            }}
          ></div>
          <div
            className='photo'
            style={{
              backgroundImage: `url(${doi})`,
            }}
          ></div>
          <div
            className='photo'
            style={{
              backgroundImage: "url('https://via.placeholder.com/302')",
            }}
          ></div>
        </div>
      </section>

      <footer>
        <p>Made by TiDi ❤️</p>
      </footer>
    </>
  )
}

export default Home
