import React, { useEffect, useRef } from 'react'

const Heart = () => {
  const canvasRef = useRef(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')

    const settings = {
      particles: {
        length: 2000,
        duration: 2,
        velocity: 100,
        effect: -1.3,
        size: 13,
      },
    }

    class Point {
      constructor(x, y) {
        this.x = x || 0
        this.y = y || 0
      }
      clone() {
        return new Point(this.x, this.y)
      }
      length(length) {
        if (length === undefined)
          return Math.sqrt(this.x * this.x + this.y * this.y)
        this.normalize()
        this.x *= length
        this.y *= length
        return this
      }
      normalize() {
        const length = this.length()
        this.x /= length
        this.y /= length
        return this
      }
    }

    class Particle {
      constructor() {
        this.position = new Point()
        this.velocity = new Point()
        this.acceleration = new Point()
        this.age = 0
      }
      initialize(x, y, dx, dy) {
        this.position.x = x
        this.position.y = y
        this.velocity.x = dx
        this.velocity.y = dy
        this.acceleration.x = dx * settings.particles.effect
        this.acceleration.y = dy * settings.particles.effect
        this.age = 0
      }
      update(deltaTime) {
        this.position.x += this.velocity.x * deltaTime
        this.position.y += this.velocity.y * deltaTime
        this.velocity.x += this.acceleration.x * deltaTime
        this.velocity.y += this.acceleration.y * deltaTime
        this.age += deltaTime
      }
      draw(context, image) {
        function ease(t) {
          return --t * t * t + 1
        }
        const size = image.width * ease(this.age / settings.particles.duration)
        context.globalAlpha = 1 - this.age / settings.particles.duration
        context.drawImage(
          image,
          this.position.x - size / 2,
          this.position.y - size / 2,
          size,
          size
        )
      }
    }

    class ParticlePool {
      constructor(length) {
        this.particles = new Array(length)
        this.firstActive = 0
        this.firstFree = 0
        this.duration = settings.particles.duration
        for (let i = 0; i < this.particles.length; i++) {
          this.particles[i] = new Particle()
        }
      }
      add(x, y, dx, dy) {
        this.particles[this.firstFree].initialize(x, y, dx, dy)
        this.firstFree++
        if (this.firstFree === this.particles.length) this.firstFree = 0
        if (this.firstActive === this.firstFree) this.firstActive++
        if (this.firstActive === this.particles.length) this.firstActive = 0
      }
      update(deltaTime) {
        let i
        if (this.firstActive < this.firstFree) {
          for (i = this.firstActive; i < this.firstFree; i++)
            this.particles[i].update(deltaTime)
        }
        if (this.firstFree < this.firstActive) {
          for (i = this.firstActive; i < this.particles.length; i++) {
            this.particles[i].update(deltaTime)
          }
          for (i = 0; i < this.firstFree; i++) {
            this.particles[i].update(deltaTime)
          }
        }
        while (
          this.particles[this.firstActive].age >= this.duration &&
          this.firstActive !== this.firstFree
        ) {
          this.firstActive++
          if (this.firstActive === this.particles.length) this.firstActive = 0
        }
      }
      draw(context, image) {
        if (this.firstActive < this.firstFree) {
          for (let i = this.firstActive; i < this.firstFree; i++) {
            this.particles[i].draw(context, image)
          }
        }
        if (this.firstFree < this.firstActive) {
          for (let i = this.firstActive; i < this.particles.length; i++) {
            this.particles[i].draw(context, image)
          }
          for (let i = 0; i < this.firstFree; i++) {
            this.particles[i].draw(context, image)
          }
        }
      }
    }

    const pointOnHeart = (t) => {
      return new Point(
        160 * Math.pow(Math.sin(t), 3),
        130 * Math.cos(t) -
          50 * Math.cos(2 * t) -
          20 * Math.cos(3 * t) -
          10 * Math.cos(4 * t) +
          25
      )
    }

    const createParticleImage = () => {
      const imgCanvas = document.createElement('canvas')
      const imgContext = imgCanvas.getContext('2d')
      imgCanvas.width = settings.particles.size
      imgCanvas.height = settings.particles.size
      imgContext.beginPath()
      let t = -Math.PI
      let point = pointOnHeart(t)
      imgContext.moveTo(point.x, point.y)
      while (t < Math.PI) {
        t += 0.01
        point = pointOnHeart(t)
        imgContext.lineTo(point.x, point.y)
      }
      imgContext.closePath()
      imgContext.fillStyle = '#FF5CA4'
      imgContext.fill()
      const image = new Image()
      image.src = imgCanvas.toDataURL()
      return image
    }

    const image = createParticleImage()

    const particles = new ParticlePool(settings.particles.length)
    let particleRate = settings.particles.length / settings.particles.duration
    let time

    const render = () => {
      const newTime = new Date().getTime() / 1000
      const deltaTime = newTime - (time || newTime)
      time = newTime

      context.clearRect(0, 0, canvas.width, canvas.height)

      const amount = particleRate * deltaTime
      for (let i = 0; i < amount; i++) {
        const pos = pointOnHeart(Math.PI - 2 * Math.PI * Math.random())
        const dir = pos.clone().length(settings.particles.velocity)
        particles.add(
          canvas.width / 2 + pos.x,
          canvas.height / 2 - pos.y,
          dir.x,
          -dir.y
        )
      }

      particles.update(deltaTime)
      particles.draw(context, image)

      requestAnimationFrame(render)
    }

    const onResize = () => {
      canvas.width = window.innerWidth // Đặt chiều rộng canvas bằng chiều rộng cửa sổ
      canvas.height = window.innerHeight // Đặt chiều cao canvas bằng chiều cao cửa sổ
    }

    window.onresize = onResize

    setTimeout(() => {
      onResize()
      render()
    }, 10)
  }, [])

  return (
    <div
      style={{
        height: '100vh',
        backgroundColor: '#000',
        display: 'flex',
        flexDirection: 'column', // Đặt hướng của flexbox theo chiều dọc
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <canvas
        ref={canvasRef}
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      />
      <div
        className='center-text'
        style={{
          color: '#FF5CA4',
          fontSize: '31px',
          fontStyle: 'italic',
          textAlign: 'center',
          marginTop: '500px', // Điều chỉnh khoảng cách từ trái tim đến dòng chữ
          position: 'relative', // Để căn giữa văn bản
        }}
      >
        Diiiiiiii
      </div>
    </div>
  )
}

export default Heart
